export const formatDateTime = (
  ts: { sec: number } | { msec: number },
  opts?: {
    formateDateOpts?: {
      sep?: 'japanese' | 'slash'
      omitCurrentYear?: boolean
      omitCurrentDay?: boolean
      showDayOfWeek?: boolean
    }
  }
) => {
  return [formatDate(ts, opts?.formateDateOpts), formatTime(ts)].join(
    opts?.formateDateOpts?.showDayOfWeek ? '' : ' '
  )
}

export const formatDateTimeOpt = (
  ts: { sec?: number } | { msec?: number },
  opts?: {
    formateDateOpts?: {
      sep?: 'japanese' | 'slash'
      omitCurrentYear?: boolean
      omitCurrentDay?: boolean
      showDayOfWeek?: boolean
    }
  }
) => {
  if ('sec' in ts) {
    if (ts.sec == null) return undefined
    return formatDateTime({ sec: ts.sec }, opts)
  }
  if ('msec' in ts) {
    if (ts.msec == null) return undefined
    return formatDateTime({ msec: ts.msec }, opts)
  }
}

const dayOfWeeks = ['日', '月', '火', '水', '木', '金', '土']

const toJSTDate = (timestamp: number): Date => {
  // Convert the timestamp to a date and adjust to JST (UTC+9)
  const date = new Date(timestamp)
  const utc = date.getTime() + date.getTimezoneOffset() * 60 * 1000
  const jstOffset = 9 * 60 * 60 * 1000 // JST offset in milliseconds
  return new Date(utc + jstOffset)
}

export const formatDate = (
  ts: { sec: number } | { msec: number },
  opts?: {
    omitCurrentYear?: boolean
    omitCurrentDay?: boolean
    sep?: 'japanese' | 'slash'
    showDayOfWeek?: boolean
  }
) => {
  const seps =
    (opts?.sep ?? 'japanese') === 'japanese'
      ? ['年', '月', '日']
      : ['/', '/', '']
  const d = toJSTDate('sec' in ts ? ts.sec * 1000 : ts.msec)

  let txt

  if (opts?.omitCurrentDay && isSameDate(ts, { msec: Date.now() })) {
    return ''
  } else if (
    opts?.omitCurrentYear &&
    new Date().getFullYear() === d.getFullYear()
  ) {
    txt = `${d.getMonth() + 1}${seps[1]}${d.getDate()}${seps[2]}`
  } else {
    txt = `${d.getFullYear()}${seps[0]}${d.getMonth() + 1}${
      seps[1]
    }${d.getDate()}${seps[2]}`
  }
  if (opts?.showDayOfWeek) {
    txt += `（${dayOfWeeks[d.getDay()]}）`
  }
  return txt
}

export const formatDateOpt = (
  ts: { sec?: number } | { msec?: number },
  opts?: {
    omitCurrentYear?: boolean
    omitCurrentDay?: boolean
    sep?: 'japanese' | 'slash'
    showDayOfWeek?: boolean
  }
) => {
  if ('sec' in ts) {
    if (ts.sec == null) return undefined
    return formatDate({ sec: ts.sec }, opts)
  }
  if ('msec' in ts) {
    if (ts.msec == null) return undefined
    return formatDate({ msec: ts.msec }, opts)
  }
}

export const formatTime = (
  ts: { sec: number } | { msec: number },
  opts?: { sep?: 'japanese' }
) => {
  const time = 'sec' in ts ? ts.sec * 1000 : ts.msec
  const offsetFromJST = new Date(time).getTimezoneOffset() / 60 + 9
  const jstTime = time + offsetFromJST * 60 * 60 * 1000
  const seps = opts?.sep === 'japanese' ? ['時', '分'] : [':', '']
  const d = new Date(jstTime)
  return `${d.getHours()}${seps[0]}${d
    .getMinutes()
    .toString()
    .padStart(2, '0')}${seps[1]}`
}

export const formatTimeOpt = (
  ts: { sec?: number } | { msec?: number },
  opts?: { sep?: 'japanese' }
) => {
  if ('sec' in ts) {
    if (ts.sec == null) return undefined
    return formatTime({ sec: ts.sec }, opts)
  }
  if ('msec' in ts) {
    if (ts.msec == null) return undefined
    return formatTime({ msec: ts.msec }, opts)
  }
}

export const formatBirthday = (y: string, m: string, d: string) => {
  let birthday = undefined
  if (y != '' && m != '' && d != '') {
    birthday = [y, m.padStart(2, '0'), d.padStart(2, '0')].join('-')
  }
  return birthday
}

export const isSameDate = (
  x: { sec: number } | { msec: number },
  y: { sec: number } | { msec: number }
) => {
  const xd = toJSTDate('sec' in x ? x.sec * 1000 : x.msec)
  const yd = toJSTDate('sec' in y ? y.sec * 1000 : y.msec)
  return (
    xd.getFullYear() === yd.getFullYear() &&
    xd.getMonth() === yd.getMonth() &&
    xd.getDate() === yd.getDate()
  )
}

export const formatRelativeDateTime = (
  ts: { sec: number } | { msec: number },
  options?: {
    omitCurrentYear?: boolean
    omitCurrentDay?: boolean
  }
) => {
  const s = 'sec' in ts ? ts.sec * 1000 : ts.msec
  const relativeTimeInMinutes = Math.floor((s - Date.now()) / (1000 * 60))

  if (relativeTimeInMinutes >= 0) {
    return '今'
  } else if (relativeTimeInMinutes > -60) {
    return new Intl.RelativeTimeFormat(['ja']).format(
      relativeTimeInMinutes,
      'minutes'
    )
  } else {
    return formatDateTime(ts, {
      formateDateOpts: {
        sep: 'slash',
        ...options,
      },
    })
  }
}

export const convertDateYYYYMMDD = (date: Date) => {
  const yyyy = date.getFullYear().toString()
  const mm = (date.getMonth() + 1).toString()
  const dd = date.getDate().toString()

  const mmChars = mm.split('')
  const ddChars = dd.split('')

  return (
    yyyy +
    '-' +
    (mmChars[1] ? mm : '0' + mmChars[0]) +
    '-' +
    (ddChars[1] ? dd : '0' + ddChars[0])
  )
}
